import ReactPlayerLoader from '@brightcove/react-player-loader'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import styles from './video.module.scss'

import Seo from '@/components/general/seo'
import Layout from '@/components/layout/layout'

import { Navigation } from '@/components/video/navigation'
import ViewingEnv from '@/components/video/viewing-env'
import { ACCOUNT_ID } from '@/lib/constants'
import { BreadcrumbContext } from '@/types/breadcrumb'

const Page: React.FC<BreadcrumbContext> = ({ pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext
  return (
    <Layout>
      <Seo title="認知機能に対する向精神薬の作用" />
      <Helmet>
        {/* <!-- Ptengine Tag --> */}
        <script src="https://js.ptengine.jp/67a5keif.js"></script>
        {/* End Ptengine Tag */}
      </Helmet>
      <div className={styles.wrap}>
        <h1 className={styles.title}>第6回 認知機能に対する向精神薬の作用</h1>
        <ReactPlayerLoader
          attrs={{ className: styles.video }}
          accountId={ACCOUNT_ID}
          videoId="6312732319112"
          playerId="hM1SBfBel"
          embedOptions={{ responsive: 'aspectRatio' }}
        />
        <div className={styles.videoBody}>
          <div className={styles.speaker}>
            <p>
              <span>ファシリテーター</span>
              <span>Lakshmi N. Yatham 教授(ブリティッシュコロンビア大学)</span>
            </p>
            <p>
              <span>エキスパート</span>
              <span>
                Joseph F. Goldberg博士 (マウントサイナイアイカーン医科大学)
              </span>
            </p>
          </div>
          <Navigation prev="5" next="8" />
          <div className={styles.paragraph}>
            <p>
              双極性障害及び統合失調症の治療で用いられる向精神病薬の有効性と安全性および認知機能に対する作用を考えます。個別化医療における薬剤選択で考慮すべきポイント、特に認知機能への影響を考慮した薬物治療戦略を学ぶことができます。
            </p>
            <p>大日本住友製薬（現：住友ファーマ株式会社）</p>
          </div>
          <ViewingEnv />
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel={'認知機能に対する向精神薬の作用'}
          />
        </div>
      </div>
    </Layout>
  )
}

export default Page
